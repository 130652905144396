import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import CV from "./components/CV"; // Make sure the path and casing match your file

// You can wrap your main page content in its own component
const MainPage = () => (
  <div>
    <Navbar />
    <div id="hero"><Hero /></div> 
    <div id="about"><About /></div> 
    <div id="portfolio"><Portfolio /></div> 
    <div id="contact"><Contact /></div> 
    <Footer />
  </div>
);

function App() {
  return (
    <Routes>
      {/* Main page route */}
      <Route path="/" element={<MainPage />} />
      {/* CV page route */}
      <Route path="/cv" element={<CV />} />
    </Routes>
  );
}

export default App;
